import DepartmentOrder from './DepartmentOrder.vue'
import DepartmentOrderCreate from './Create.vue'
import DepartmentOrderEdit from './Create.vue'

export default [
    {
        path: '/department_order',
        name: 'DepartmentOrder',
        component: DepartmentOrder
    },
    {
        path: '/department_order/create',
        name: 'DepartmentOrderCreate',
        component: DepartmentOrderCreate
    },
    {
        path: '/department_order/edit/:id',
        name: 'DepartmentOrderEdit',
        component: DepartmentOrderEdit
    },
]
