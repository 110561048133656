<template>
  <section class="section">
    <base-header>
      <template v-slot:header-table
        ><h1>{{ name }}</h1></template
      >
      <template v-slot:item-slot>
        <div class="breadcrumb-item">Department Order</div>
        <div class="breadcrumb-item">{{ name }}</div>
      </template>
    </base-header>

    <div class="section-body">
      <base-title></base-title>
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <h4>{{ name }}</h4>
            </div>
            <form @submit.prevent="departmentOrderInsert">
              <div class="card-body">
                <div class="form-row">
                  <div class="col">
                    <div class="form-group">
                      <label>Product Name</label>
                      <input
                        type="text"
                        class="form-control"
                        v-model="data.name_product"
                        disabled
                      />
                    </div>
                  </div>
                  <div class="col">
                    <div class="form-group">
                      <label>Price</label>
                      <input
                        type="text"
                        class="form-control"
                        v-model="data.price"
                        disabled
                      />
                    </div>
                  </div>
                  <div class="col">
                    <div class="form-group">
                      <label>Order ID</label>
                      <input
                        type="text"
                        class="form-control"
                        v-model="data.order_id"
                        disabled
                      />
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <label>Status</label>
                  <select
                    name=""
                    class="form-control"
                    id=""
                    v-model="form.status"
                  >
                    <option value="">Select Status</option>
                    <option
                      :value="name"
                      v-for="(s, name) in status"
                      :key="name"
                      >{{ s }}</option
                    >
                  </select>
                </div>
              </div>
              <div class="card-footer text-right">
                <button
                  class="btn btn-primary mr-1"
                  type="submit"
                  :disabled="isSubmitting"
                >
                  Submit
                </button>
                <router-link
                  :to="{ name: 'DepartmentOrder' }"
                  class="btn btn-danger"
                  >Back</router-link
                >
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import axios from "axios";
import { apiGet } from "../../services/api";
import { DepartmentOrderRepository } from "../../repositories/RepositoryFactory";
export default {
  name: "FormDepartmentOrder",
  data() {
    return {
      name: "Form Department Order",
      data: [],
      isSubmitting: false,
      errors: {},
      edit: false,
      status: [],
      form: {
        status: "",
      },
    };
  },
  mounted() {
    this.getData();
    this.getStatus();
  },
  methods: {
    getData() {
      let id = this.$route.params.id;
      apiGet("order_department/" + id)
        .then((result) => {
          this.data = result.data.data;
          this.form.status = result.data.data.status;
          this.edit = true;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getStatus() {
      DepartmentOrderRepository.getStatus().then((result) => {
        this.status = result.data;
      });
    },

    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    departmentOrderInsert() {
      this.isSubmitting = true;
      let id = this.$route.params.id;
      axios
        .post(
          this.$store.state.api + "order_department/update/" + id,
          this.form,
          {
            headers: {
              Authorization: this.$store.state.token,
            },
          }
        )
        .then(() => {
          this.$noty.success("Your order department has been updated!");
          this.$router.push({ name: "DepartmentOrder" });
        })
        .catch((error) => {
          this.errors = error.response.data;
          this.isSubmitting = false;
        });
    },
  },
};
</script>
